import { useSpring, animated } from '@react-spring/web'
import { useScrollProgression, triggers } from '@kaliber/scroll-progression'

import { routeMap } from '/routeMap'
import { useLanguage, useTranslate } from '/machinery/I18n'

import { ImageCover } from '/features/buildingBlocks/Image'
import { Icon } from '/features/buildingBlocks/Icon'

import styles from './XxlCampaignBanner.css'

import arrowIcon from '/images/icons/arrow-right.raw.svg'

export function XxlCampaignBanner({ image, layoutClassName = undefined }) {
  const [{ y, maskSize, ...style }, api] = useSpring(() => ({
    y: '150%',
    maskSize: '15%',
    scale: 1,
    config: { mass: 1, tension: 100, friction: 20 },
  }))

  const sectionRef = useScrollProgression({
    start: { element: triggers.top(), scrollParent: triggers.custom(0.5) },
    end: { element: triggers.bottom(), scrollParent: triggers.custom(0.2) },
    onChange(progression) {
      api.start({
        y: progression > 0.35 ? '0%' : '150%',
        maskSize: `${Math.max(15, easeInOutCubic(progression) * 400)}%`,
        scale: 1.3 - Math.min(0.3, progression)
      })
    }
  })

  return (
    <section ref={sectionRef} className={cx(styles.component, layoutClassName)}>
      {image && <Window layoutClassName={styles.windowLayout} {...{ maskSize, y, style, image }} />}
    </section>
  )
}

function Window({ maskSize, y, style, image, layoutClassName = undefined }) {
  return (
    <animated.figure style={{ maskSize }} className={cx(styles.componentWindow, layoutClassName)}>
      <animated.span className={styles.image} {...{ style }}>
        <ImageCover aspectRatio={5 / 7} layoutClassName={styles.imageLayout} {...{ image }} />
      </animated.span>
      <Button layoutClassName={styles.buttonLayout} {...{ y }} />
    </animated.figure>
  )
}

function Button({ y, layoutClassName = undefined }) {
  const { __ } = useTranslate()
  const language = useLanguage()

  return (
    <animated.a
      aria-label='link to engineering & maintenance'
      data-x='link-in-banner-to-xxl-campaign'
      href={routeMap.app.xxlCampaignPage({ language })}
      style={{ y }}
      className={cx(styles.componentButton, layoutClassName)}
    >
      <span className={styles.buttonLabel}>{__`explore-engineering-maintenance`}</span>
      <span className={styles.iconContainer}>
        <Icon icon={arrowIcon} layoutClassName={styles.iconLayout} />
      </span>
    </animated.a>
  )
}

function easeInOutCubic(x) {
  return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2
}
